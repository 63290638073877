<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen add-application icon-app1 edit-connector"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_reports',
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("reports.reports." + $route.params.report) }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" style="display: none;">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="firstNameId"
              >{{ displayLabelName("reports.reports.auto-release") }}
            </label>
          </div>
          <button
            @click="$router.push({ name: 'r_report-auto-release' })"
            class="follow-up-icons"
          >
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12"></icon>
            </span>
          </button>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="firstNameId"
              >{{ displayLabelName("reports.reports.export") }}
            </label>
          </div>
          <button @click="download()" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-men1-download-sample"></icon>
            </span>
          </button>
        </div>
      </li>
    </ul>

    <template v-slot:footer></template>
    <div class="status-dialog" v-if="declarationsModal">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <!-- Status info -->
          <br />

          <div
            class="dialog-body"
            style="flex-direction: column; padding-top: 0px; width: 80%;"
          >
            <div class="dialog-body-row" style="flex-direction: column;">
              <label> {{ displayLabelName("export.options.options") }}</label
              ><br />
              <button class="export-button" @click="download('last3months')">
                {{ displayLabelName("export.options.last-three-months") }}
              </button>
              <button class="export-button" @click="download('currentYear')">
                {{ displayLabelName("export.options.current-year") }}
              </button>
              <button class="export-button" @click="download('lastYear')">
                {{ displayLabelName("export.options.last-year") }}
              </button>
            </div>
          </div>
          <button
            style="border-top: 1px solid rgb(184, 184, 184); width: 100%; padding-top: 10px; padding-bottom: 3px;"
            @click="declarationsModal = false"
          >
            {{ displayLabelName("global.buttons.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";

export default {
  name: "Report",
  data() {
    return {
      declarationsModal: false,
      name: "",
      url: "",
    };
  },
  computed: {
    apiEp() {
      return apiEndpoints;
    },
  },
  methods: {
    download(declarationPeriod = null) {
      if (
        this.$route.params.report == "reservations" &&
        this.declarationsModal == false
      ) {
        this.declarationsModal = true;
        return;
      }
      this.declarationsModal = false;
      if (this.$route.params.report == "reservations") {
        this.name = "Clebex - Export declarations.csv";
        this.url = apiEndpoints.company.exportDeclarations;
      } else if (this.$route.params.report == "services") {
        this.name = "Clebex - Export services.csv";
        this.url = apiEndpoints.company.exportServices;
      } else {
        return;
      }

      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (declarationPeriod) {
        this.url += "?period=" + declarationPeriod;
      }

      return httpServiceAuth
        .get(this.url, {
          exposedHeaders: ["Content-Disposition"],
        })
        .then((response) => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = name;
          if (contentDispositionHeader) {
            const headerFileName = this.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.declarationsModal = false;
        });
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  mixins: [globalMixin],
};
</script>
<style>
.export-button {
  width: 100%;
  background-color: rgba(36, 183, 175, 0.8);
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}
</style>
